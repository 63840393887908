import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = Component => props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    ),
                },
                { path: 'login-unprotected', element: <Login /> },
                { path: 'register-unprotected', element: <Register /> },
                { path: 'reset-password', element: <ResetPassword /> },
                { path: 'new-password', element: <NewPassword /> },
                { path: 'verify', element: <VerifyCode /> },
            ],
        },

        // Dashboard Routes
        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                { path: 'app', element: <PatientManager/> },
                {
                    path: 'organization',
                    children: [
                        { element: <Navigate to="/dashboard/organization" replace />, index: true },
                        { path: 'manage', element: <RelationshipManager /> },
                    ],
                },
                {
                    path: 'tenantadmin',
                    children: [
                        { element: <Navigate to="/dashboard/tenantadmin" replace />, index: true },
                        { path: 'account', element: <UserAccount /> },
                        { path: 'nursemanager', element: <UserNurseManager /> },
                        { path: 'nurse', element: <UserNurse /> },
                        { path: 'medicalassistant', element: <UserNurseMedAssist /> },
                        { path: 'patient', element: <PatientManager /> }, 
                    ],
                },
                {
                    path: 'patient',
                    children: [
                        { element: <Navigate to="/dashboard/patient/profile" replace />, index: true },
                        { path: ':id/new', element: <PatientCreate /> },
                        { path: 'list', element: <PatientManager /> },
                        { path: ':id/edit', element: <PatientCreate /> },
                        { path: 'readings', element: <PatientReadings /> },
                        { path: 'misseduploads', element: <PatientMissedUploads /> },
                        { path: 'servicetime', element: <PatientServiceTime /> },
                        //{ path: 'revenue', element: <PatientRevenue /> },
                        { path: ':id/view', element: <PatientView /> },
                        { path: ':id/ccmnew', element: <PatientCcmCreate /> },
                        { path: ':id/ccmedit', element: <PatientCcmCreate /> },
                    ],
                },
                {
                    path: 'device',
                    children: [
                        { element: <Navigate to="/dashboard/device/list" replace />, index: true },
                       // { path: 'manage', element: <DeviceManager /> },
                        //{ path: 'tracking', element: <DeviceTracking /> },
                    ],
                },

                { path: 'permission-denied', element: <PermissionDenied /> },
                { path: 'settings', element: <UserSettings /> },
            ],
        },

        // Main Routes
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: 'coming-soon', element: <ComingSoon /> },
                { path: 'maintenance', element: <Maintenance /> },
                { path: '500', element: <Page500 /> },
                { path: '404', element: <Page404 /> },
                { path: '403', element: <Page403 /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        {
            path: '/',
            element: <MainLayout />,
            children: [{ element: <HomePage />, index: true }],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// GENERAL
////const DashboardMain = Loadable(lazy(() => import('../pages/dashboard/DashboardMain')));

// USER
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UsersAccount')));
const UserSettings = Loadable(lazy(() => import('../pages/dashboard/UserSettings')));
const UserNurseManager = Loadable(lazy(() => import('../pages/dashboard/UserNurseManager')));
const UserNurse = Loadable(lazy(() => import('../pages/dashboard/UserNurse')));
const UserNurseMedAssist = Loadable(lazy(() => import('../pages/dashboard/UserNurseMedAssist')));

// PATIENT
const PatientCreate = Loadable(lazy(() => import('../pages/dashboard/PatientCreate')));
const PatientCcmCreate = Loadable(lazy(() => import('../pages/dashboard/PatientCcmCreate')));
const PatientManager = Loadable(lazy(() => import('../pages/dashboard/PatientManager')));
const PatientReadings = Loadable(lazy(() => import('../pages/dashboard/PatientReadings')));
const PatientMissedUploads = Loadable(lazy(() => import('../pages/dashboard/PatientMissedUploads')));
const PatientServiceTime = Loadable(lazy(() => import('../pages/dashboard/PatientServiceTime')));
//const PatientRevenue = Loadable(lazy(() => import('../pages/dashboard/PatientRevenue')));
const PatientView = Loadable(lazy(() => import('../pages/dashboard/PatientView')));

// DEVICE
//const DeviceManager = Loadable(lazy(() => import('../pages/dashboard/DeviceManager')));
//const DeviceTracking = Loadable(lazy(() => import('../pages/dashboard/DeviceTracking')));

const RelationshipManager = Loadable(lazy(() => import('../pages/dashboard/RelationshipManager')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionsDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
